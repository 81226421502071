import React from 'react'

const Arrow = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
      <path
        d="M270.6,364.9L459.5,176c11.2-11.2,11.2-29.4,0-40.6l0,0c-11.2-11.2-29.4-11.2-40.6,0L250.2,304.1l-169-169
            c-11.2-11.2-29.4-11.2-40.6,0l0,0c-11.2,11.2-11.2,29.4,0,40.6l188.9,188.9c2.2,2.2,4.8,4,7.5,5.4
            C247.7,375.7,261.5,374,270.6,364.9z"
      />
    </svg>
  )
}
export default Arrow
