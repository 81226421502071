import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import LoadMore from '@/components/LoadMore'
import Select from '@/components/Select'
import moment from 'moment'

interface PostCat {
  name: string
  slug: string
  id: string
  uri: string
}

interface ArchiveData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      featuredImage: any
    }
    posts: any
    menu?: any
    quickmenu?: any
    categories: {
      nodes: Array<PostCat>
    }
  }
  pageContext: {
    postlang: string
  }
}

const ArchiveTemplate = ({ data }: ArchiveData) => {
  // Page data
  const { page, posts, categories, quickmenu } = data

  const { menu } = data

  const heroContent = {
    image: page?.featuredImage,
  }

  const [year, setYear] = useState('')
  const [type, setType] = useState('')
  const [yearFilters, setYearFilters] = useState([])

  const setYearFilter = (currentYear: string) => {
    setYear(currentYear)
  }

  const setTypeFilter = (currentType: string) => {
    setType(currentType)
  }

  const filterDate = (item: any) => {
    if (moment(item.date).format('YYYY') === year || year === '') {
      return true
    }
  }

  const filterTerms = (item: any) => {
    if (item?.terms?.nodes.some((t: any) => t?.name === type || type === '')) {
      return true
    } else if ((item?.terms?.nodes.length === 0 && type == '') || (item?.terms === null && type == '')) {
      return true
    }
  }

  const filterResults = () => {
    const filterYear: any = posts?.nodes.filter(filterDate)
    const FilterTerm: any = filterYear?.filter(filterTerms)
    return FilterTerm
  }

  const results = filterResults()
  const testkey = new Date()
  const loadmoreKey = testkey.getTime()

  const currentYear = moment().year()

  useEffect(() => {
    const years: any = []

    for (let i = 0; i < 3; i++) {
      years.push(currentYear - i)
    }

    setYearFilters(years)
  }, [])

  return (
    <Layout title={page?.title} hero={heroContent} nav={menu} quickNav={quickmenu} seo={page?.seo} uri={page?.uri}>
      <div className="flex md:flex-row flex-col mb-10 items-start md:items-center">
        <div>
          <button
            className={`hover:text-primary focus:text-primary y-2 mr-10 font-FuturaBol ${
              type === '' ? 'text-primary' : ''
            }`}
            onClick={() => setTypeFilter('')}
          >
            Kaikki
          </button>
          {categories.nodes.map(cat => {
            return (
              <button
                className={`hover:text-primary focus:text-primary y-2 mr-10 font-FuturaBol ${
                  type === cat.name ? 'text-primary' : ''
                }`}
                onClick={() => setTypeFilter(`${cat.name ?? ''}`)}
                key={cat.id}
              >
                {cat.name}
              </button>
            )
          })}
        </div>
        <Select className="md:ml-auto mt-10 md:mt-0" selectName={'Vuosi'} activeSelect={year}>
          <button
            className="hover:bg-fadeblackmin hover:text-white focus:bg-black hover:bg-black focus:text-white py-2"
            onClick={() => setYearFilter('')}
          >
            Kaikki
          </button>
          {yearFilters.map((item: any) => {
            return (
              <button
                key={item}
                className="hover:bg-fadeblackmin hover:text-white focus:bg-black hover:bg-black focus:text-white py-2"
                onClick={() => setYearFilter(`${item ?? ''}`)}
              >
                {item}
              </button>
            )
          })}
        </Select>
      </div>
      <LoadMore key={loadmoreKey} className="grid sm:grid-cols-2 gap-8" posts={results} />
    </Layout>
  )
}

export const PageQuery = graphql`
  query ArchiveById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      ...seo
      ...featuredHeroImage
    }
    categories: allWpCategory(filter: { name: { ne: "Yleinen" } }) {
      nodes {
        name
        slug
        id
        uri
      }
    }
    posts: allWpPost(sort: { order: DESC, fields: date }) {
      ...allPosts
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default ArchiveTemplate
